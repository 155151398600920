@charset "UTF-8";
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 200;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src: local("NotoSansJP-Regular.otf"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.otf) format("opentype"); }

/* @mixin sp-screen-m() {
	@include mq($max-width: $tab-brk-short-m) {
	    @content;
	}
}
 */
@keyframes flashing {
  0% {
    opacity: 0; }
  100% {
    opacity: 100; } }

* {
  margin: 0;
  padding: 0; }

html {
  min-height: 100%;
  position: relative;
  box-sizing: border-box; }

html *, html *:before, html *:after {
  box-sizing: inherit; }

h1, h2, h3 {
  font-weight: normal;
  margin: 0; }

img {
  max-width: 100%;
  vertical-align: middle; }

body {
  background: url(../img/bg_ptn.gif) repeat;
  color: #6e5748;
  font-size: 14px;
  line-height: 1.5;
  font-family: "游明朝体", "Yu Mincho", YuMincho, serif;
  padding-bottom: 30px; }

button, input {
  font-family: #232323;
  font-size: 100%; }

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

a {
  color: #232323;
  outline: none;
  cursor: pointer; }

button {
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  border: none; }

.is-spbr {
  display: none; }
  @media only screen and (max-width: 768px) {
    .is-spbr {
      display: block; } }

.is-pcbr {
  display: block; }
  @media only screen and (max-width: 768px) {
    .is-pcbr {
      display: none; } }

.l-container {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  @media only screen and (max-width: 768px) {
    .l-container {
      width: auto;
      display: block; } }

.l-main {
  width: 700px; }
  @media only screen and (max-width: 768px) {
    .l-main {
      width: auto; } }

.l-content {
  width: 620px;
  margin: auto; }
  @media only screen and (max-width: 768px) {
    .l-content {
      padding: 0 16px;
      width: 100%; } }

.l-page-title {
  margin-top: 40px;
  margin-bottom: 70px;
  padding-bottom: 5px;
  line-height: 1.4;
  border-bottom: 1px solid #232323;
  font-size: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .l-page-title span {
    font-size: 20px; }
  @media only screen and (max-width: 768px) {
    .l-page-title {
      font-size: 20px;
      margin: 50px 16px 25px 16px; }
      .l-page-title span {
        font-size: 10px; } }

.p-social-twitter {
  margin-right: 27px; }
  @media only screen and (max-width: 768px) {
    .p-social-twitter {
      margin-right: 0; } }
  .p-social-twitter .p-social__icon {
    width: 21px; }
    @media only screen and (max-width: 768px) {
      .p-social-twitter .p-social__icon {
        width: 32px; } }

.p-social-facebook {
  margin-right: 27px; }
  @media only screen and (max-width: 768px) {
    .p-social-facebook {
      margin-right: 0; } }
  .p-social-facebook .p-social__icon {
    width: 12px; }
    @media only screen and (max-width: 768px) {
      .p-social-facebook .p-social__icon {
        width: 16px; } }

.p-social-line {
  display: none; }
  @media only screen and (max-width: 768px) {
    .p-social-line {
      display: block; } }
  .p-social-line .p-social__icon {
    width: 40px; }
    @media only screen and (max-width: 768px) {
      .p-social-line .p-social__icon {
        width: 70px; } }

.p-nav__social .p-social__icon:hover {
  opacity: .6; }

@media only screen and (max-width: 768px) {
  .p-nav__social {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center; } }

.l-main-content {
  line-height: 2.2; }

.p-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 30;
  display: none; }

.p-modal__bg {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0; }

.p-modal__contain {
  background-color: #fff;
  position: absolute;
  width: 70vw;
  height: 70vh;
  left: 15vw;
  top: 15vh;
  padding: 0 5vw 0;
  border: 2px solid #32c7ff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 100%;
  font-size: 2.1vh;
  line-height: 2.4; }
  @media only screen and (max-width: 768px) {
    .p-modal__contain {
      width: 90vw;
      height: 90vh;
      left: 5vw;
      top: 5vh;
      font-size: 100%;
      font-size: 2.3vh;
      line-height: 1.8; } }

.p-modal__title {
  width: 100%;
  margin-bottom: 1.5em;
  font-size: 120%;
  line-height: 1; }
  @media only screen and (max-width: 768px) {
    .p-modal__title {
      font-size: 100%;
      text-align: center; } }

.p-modal__title-role {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 10px;
  margin-top: 30px;
  font-size: 18px; }
  .p-modal__title-role::after {
    content: ":"; }
  @media only screen and (max-width: 768px) {
    .p-modal__title-role {
      font-size: 1.1em; } }

.p-modal__title-name {
  font-size: 1.4em;
  display: inline-block; }

.p-modal__content {
  width: 100%;
  font-size: 90%; }

.p-modal__close {
  position: absolute;
  top: 2vw;
  right: 2vw;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; }

.u-p-modal-btn-close {
  vertical-align: baseline;
  display: block;
  color: #32c7ff;
  line-height: 1.6;
  text-decoration: none;
  font-size: 18px; }
  .u-p-modal-btn-close img {
    width: 16px;
    margin-left: 5px;
    margin-bottom: 3px; }

.u-show-modal .p-modal {
  display: block; }

.l-header {
  width: 320px;
  margin-left: 70px;
  margin-right: 70px;
  padding-top: 50px;
  transition: .4s; }
  @media only screen and (max-width: 768px) {
    .l-header {
      width: auto;
      margin: 0;
      padding: 0; } }

.p-header__logo {
  margin-bottom: 47px;
  margin-top: 20px; }
  @media only screen and (max-width: 768px) {
    .p-header__logo {
      padding: 0 60px 0 25px;
      margin-bottom: 0; }
      .p-header__logo a {
        display: block;
        height: 32px;
        background: url(../img/logo_sp.png) left center no-repeat;
        background-size: contain; }
        .p-header__logo a img {
          display: none; } }

@media only screen and (max-width: 768px) {
  [data-pagename="Top"] .p-header__logo {
    display: none; } }

.p-header__broadcast {
  border-top: 1px solid rgba(110, 87, 72, 0.4);
  border-bottom: 1px solid rgba(110, 87, 72, 0.4);
  padding: 10px 0;
  font-size: 17.6px;
  text-align: center;
  margin-bottom: 30px; }
  .p-header__broadcast small {
    font-size: 14px;
    line-height: 1.4;
    display: block; }
  .p-header__broadcast .p-copy__amazon {
    font-size: 76%;
    line-height: 1.4;
    margin-top: 1rem;
    display: inline-block;
    text-align: left; }
  @media only screen and (max-width: 768px) {
    .p-header__broadcast {
      display: none; } }

.js-drawerButton {
  display: none; }
  @media only screen and (max-width: 768px) {
    .js-drawerButton {
      z-index: 10;
      display: block;
      position: fixed;
      top: 10px;
      right: 10px; }
      .js-drawerButton img {
        width: 40px; } }

@media only screen and (max-width: 768px) {
  .p-nav {
    position: fixed;
    top: 60px;
    height: calc(100vh - 60px);
    width: 100vw;
    left: 0;
    background-color: #fff;
    transform: translateX(100vw);
    transition: .4s;
    z-index: 9; } }

.p-nav .p-nav__content {
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }
  .p-nav .p-nav__content li {
    width: 50%;
    /*&:nth-child(-n+2) {
				a {
					&::before {
						display: none;
					}
				}
			}*/ }
    .p-nav .p-nav__content li a {
      color: #6e5748;
      display: block;
      margin: 2px;
      text-decoration: none;
      font-size: 90%;
      line-height: 1;
      padding: 14px 0 10px 10px;
      position: relative; }
      .p-nav .p-nav__content li a::before {
        content: "";
        position: absolute;
        width: calc( 100% - 2px);
        height: 1px;
        display: block;
        left: 0;
        bottom: -2px;
        background-color: rgba(110, 87, 72, 0.4); }
      .p-nav .p-nav__content li a:hover span {
        opacity: .4; }
      .p-nav .p-nav__content li a.disabled {
        cursor: default; }
        .p-nav .p-nav__content li a.disabled span {
          opacity: .4; }
      .p-nav .p-nav__content li a.active span {
        font-weight: bold; }
      @media only screen and (max-width: 768px) {
        .p-nav .p-nav__content li a {
          font-size: 130%;
          padding: 24px 0 20px 20px; } }
    .p-nav .p-nav__content li:nth-child(odd) a::after {
      content: "";
      position: absolute;
      width: 1px;
      height: calc( 100% - 4px);
      display: block;
      right: -2px;
      top: 4px;
      background-color: rgba(110, 87, 72, 0.3); }

@media only screen and (max-width: 768px) {
  .is-drawer-open .l-header {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 70px;
    background-color: #fff;
    z-index: 8; }
  .is-drawer-open .l-main, .is-drawer-open .l-footer {
    display: none; }
  .is-drawer-open .p-nav {
    transform: translateX(0); } }

.l-footer {
  text-align: center;
  margin-top: 40px; }
  .l-footer small {
    font-family: "Noto Sans Japanese", sans-serif; }

.overlay_container, .overlay_youtube_container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: .3s; }

.overlay_youtube_inner {
  width: 800px;
  transition: .3s;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column; }

.overlay_youtube_inner .overlay_youtube_header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center; }

/* .overlay_youtube_inner .overlay_youtube_header .btn_close img {
      width: 40px; } */
.overlay_youtube_inner .overlay_youtube_main {
  flex: 1 0 0;
  border: 1px solid #ff9999;
  background-color: #fff;
  width: 100%;
  height: 500px;
  position: relative; }

.overlay_youtube_inner .overlay_youtube_main iframe {
  vertical-align: bottom; }

/*.open_message .overlay_container {
  z-index: 20;
  visibility: visible;
  opacity: 1; }
.open_message .overlay_inner {
  height: 50%; }
  .open_message .overlay_inner .ovl_message {
    z-index: 2;
    opacity: 1; }
  .open_message .overlay_inner .ovl_anime {
    opacity: 0;
    z-index: 1; }

*/
.overlay_manga_container, .overlay_special_container {
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.open_manga .overlay_manga_container {
  z-index: 20;
  visibility: visible;
  opacity: 1; }

.open_special .overlay_special_container {
  z-index: 20;
  visibility: visible;
  opacity: 1; }

.open_manga .overlay_inner .ovl_message {
  z-index: 1;
  opacity: 0; }

.open_manga .overlay_inner .ovl_anime {
  opacity: 1;
  z-index: 2; }

.open_youtube .overlay_youtube_container {
  z-index: 20;
  visibility: visible;
  opacity: 1; }

/*.overlay_youtube_container {
  background-color: rgba(#fff, .95);
}*/
.overlay_youtube_inner {
  width: 804px; }
  @media only screen and (max-width: 768px) {
    .overlay_youtube_inner {
      width: calc( 90% + 4px); } }
  .overlay_youtube_inner .overlay_youtube_main {
    border: 2px solid #32c7ff;
    height: auto; }
    @media only screen and (max-width: 768px) {
      .overlay_youtube_inner .overlay_youtube_main iframe {
        width: 90vw;
        height: calc(90vw * 0.5625); } }

.p-overlay__btn-close {
  margin-bottom: 10px;
  text-align: right; }
  .p-overlay__btn-close a {
    display: block;
    color: #32c7ff;
    text-decoration: none; }
    .p-overlay__btn-close a:hover {
      opacity: .6; }
  .p-overlay__btn-close img {
    width: 25px;
    margin-left: 5px;
    display: inline-block; }

.overlay_manga_container, .overlay_special_container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .overlay_manga_container .overlay_main, .overlay_special_container .overlay_main {
    height: 80vh;
    width: 50vw;
    margin: auto;
    overflow-y: auto; }
    @media only screen and (max-width: 768px) {
      .overlay_manga_container .overlay_main, .overlay_special_container .overlay_main {
        width: 96%; } }
    .overlay_manga_container .overlay_main img, .overlay_special_container .overlay_main img {
      max-width: auto;
      max-width: initial;
      width: 100%;
      vertical-align: top; }

.overlay_special_container .overlay_main {
  height: auto; }

@media only screen and (max-width: 768px) {
  .overlay_inner {
    width: 90%; } }

.open_manga .overlay_inner .overlay_main {
  overflow: auto;
  border: 2px solid #32c7ff; }
  @media only screen and (max-width: 768px) {
    .open_manga .overlay_inner .overlay_main {
      display: flex;
      justify-content: center;
      align-items: center; } }
  .open_manga .overlay_inner .overlay_main .overlay_content {
    position: relative; }
    .open_manga .overlay_inner .overlay_main .overlay_content a {
      transition: .2s; }
      .open_manga .overlay_inner .overlay_main .overlay_content a:hover {
        opacity: .8; }

.overlay_special_container, .overlay_youtube_container, .overlay_manga_container {
  background-color: rgba(255, 255, 255, 0.95); }

@keyframes loading_icon_motion {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

.l-top-container {
  align-items: stretch;
  overflow: hidden; }
  @media only screen and (min-width: 1300px) {
    .l-top-container {
      width: 1400px; } }
  .l-top-container .l-main {
    width: 740px;
    display: flex;
    justify-content: space-between;
    align-items: stretch; }
    @media only screen and (max-width: 768px) {
      .l-top-container .l-main {
        width: 100vw;
        display: block; } }
    @media only screen and (min-width: 1300px) {
      .l-top-container .l-main {
        width: 940px; } }

.p-main-visual {
  transition: opacity .6s;
  opacity: 0;
  width: calc(100% - 40px);
  position: relative;
  max-height: 900px;
  /* @include sp-950screen {
		height: calc( 100vw * 1.4225 );
	} */ }
  @media only screen and (max-width: 768px) {
    .p-main-visual {
      opacity: 1; } }
  @media only screen and (max-width: 768px) {
    .p-main-visual {
      width: auto;
      height: calc( 100vw * 1.4225); } }
  @media only screen and (min-width: 1300px) {
    .p-main-visual {
      width: 970px;
      height: 1379.825px;
      max-height: 1000px; } }

.p-main-visual__bg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  /* img {
		width: 100%;
		height: auto;
	} */
  background: url(../img/top/main_bg_201801.jpg) top center no-repeat;
  background-size: cover; }

.onloaded .p-main-visual {
  opacity: 1; }

.onloaded .p-main-visual__bg-container {
  animation: bgsky_animation 3.4s 0.1s forwards cubic-bezier(0.55, 0.085, 0.68, 0.53); }
  @media only screen and (max-width: 768px) {
    .onloaded .p-main-visual__bg-container {
      animation: none; } }

@keyframes bgsky_animation {
  0% {
    background-position: top; }
  100% {
    background-position: bottom; } }

.p-main-visual__logo {
  display: none;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 60vw;
  z-index: 2; }
  @media only screen and (max-width: 768px) {
    .p-main-visual__logo {
      display: block; } }

.p-copy__broadcast {
  display: none; }
  @media only screen and (max-width: 768px) {
    .p-copy__broadcast {
      display: block;
      margin: 20px 5vw;
      text-align: center;
      font-size: 4.2vw; }
      .p-copy__broadcast small {
        font-size: 3.8vw;
        line-height: 1.6;
        display: inline-block; }
      .p-copy__broadcast .p-copy__amazon {
        margin-top: 1em;
        text-align: left; } }

.p-side-credits {
  width: 40px; }

.p-side-credits__inner {
  width: 100vh;
  padding: 0 20px;
  transform-origin: left top;
  transform: rotate(90deg) translateY(-30px);
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  @media only screen and (max-width: 768px) {
    .p-side-credits__inner {
      display: none; } }

.p-side-credits__twitter {
  vertical-align: baseline;
  text-decoration: none; }
  .p-side-credits__twitter img {
    width: 24px;
    margin-right: 10px;
    margin-bottom: 5px; }

.p-top-content {
  padding: 50px 70px 70px; }
  @media only screen and (max-width: 768px) {
    .p-top-content {
      padding: 1rem 1rem; } }

.p-top-content__column {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  @media only screen and (max-width: 768px) {
    .p-top-content__column {
      display: block; } }

.p-top-content__column-item {
  width: 33%;
  margin-right: 50px; }
  .p-top-content__column-item:last-child {
    margin-right: 0; }
  @media only screen and (max-width: 768px) {
    .p-top-content__column-item {
      width: auto;
      margin-right: 0; } }

.p-top-content__title {
  text-align: center;
  border-bottom: 1px solid #000;
  font-size: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  line-height: 1; }

.p-recent-news li {
  margin-bottom: 10px; }
  .p-recent-news li a {
    display: block;
    text-decoration: none;
    line-height: 1.3;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; }
    .p-recent-news li a .p-recent-news__date {
      width: 30%; }
    .p-recent-news li a .p-recent-news__title {
      width: 70%; }
    .p-recent-news li a:hover {
      opacity: .4; }

.p-pv__hitarea {
  position: relative;
  height: 100%; }
  .p-pv__hitarea .pv_btn_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center; }

.p-banners {
  text-align: center; }
  .p-banners a {
    display: inline-block;
    margin: 0 15px; }
    .p-banners a img {
      width: 200px; }
    .p-banners a:hover {
      opacity: .6; }
  @media only screen and (max-width: 768px) {
    .p-banners a {
      display: block;
      margin: 30px 0; } }

.p-introduction__content > *:last-child {
  margin-bottom: 0; }

@media only screen and (max-width: 768px) {
  .p-introduction__content {
    width: auto; } }

.p-introduction__content p {
  margin-bottom: 2em;
  letter-spacing: 0.08em; }
  @media only screen and (max-width: 768px) {
    .p-introduction__content p {
      font-size: 100%;
      line-height: 1.5;
      margin-bottom: 1em; } }

@media only screen and (max-width: 768px) {
  .p-introduction__content-box {
    margin-bottom: 1em; } }

.p-introduction__column {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  @media only screen and (max-width: 768px) {
    .p-introduction__column {
      flex-direction: column; } }
  .p-introduction__column > * {
    flex: 1; }

.l-introduction__figure {
  width: 290px; }
  @media only screen and (max-width: 768px) {
    .l-introduction__figure {
      width: 100%;
      margin-bottom: 1em; } }
  .l-introduction__figure img {
    width: 100%;
    margin-bottom: 35px; }
    @media only screen and (max-width: 768px) {
      .l-introduction__figure img {
        margin: 0; } }

.l-introduction__content-box-right.l-introduction__figure {
  margin-left: 40px; }
  @media only screen and (max-width: 768px) {
    .l-introduction__content-box-right.l-introduction__figure {
      margin-left: 0; } }

.l-introduction__content-box-left.l-introduction__figure {
  margin-right: 40px; }
  @media only screen and (max-width: 768px) {
    .l-introduction__content-box-left.l-introduction__figure {
      margin-right: 0; } }

.p-introduction__end-copy {
  font-size: 160%; }
  .p-introduction__end-copy span {
    letter-spacing: 0; }

.p-artist {
  margin-bottom: 40px; }

.l-song-title {
  font-size: 22px;
  color: #32c7ff;
  font-weight: normal;
  margin-bottom: 20px; }
  .l-song-title strong {
    display: block;
    margin-top: 10px;
    font-size: 20px;
    color: #232323;
    font-weight: normal;
    margin-top: 10px;
    line-height: 1.5; }
  .l-song-title .l-sp-br {
    display: none; }
  @media only screen and (max-width: 768px) {
    .l-song-title {
      text-align: center; }
      .l-song-title .l-sp-br {
        display: block; } }

.p-music__content-box {
  margin-bottom: 20px; }
  .p-music__content-box p {
    margin-bottom: 1em; }

.p-music__column {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row-reverse; }
  .p-music__column > * {
    flex: 1; }
    @media only screen and (max-width: 768px) {
      .p-music__column > * {
        margin-bottom: 20px; } }
  @media only screen and (max-width: 768px) {
    .p-music__column {
      display: block; } }

.l-music__figure {
  width: 290px;
  margin-right: 40px; }
  .l-music__figure img {
    width: 100%; }
  .l-music__figure figcaption {
    font-size: 14px;
    line-height: 1.1;
    margin-top: 20px; }
    .l-music__figure figcaption a {
      display: block;
      margin-top: 5px;
      color: #32c7ff; }
    @media only screen and (max-width: 768px) {
      .l-music__figure figcaption {
        text-align: right; } }
  @media only screen and (max-width: 768px) {
    .l-music__figure {
      padding: 0 20px;
      width: 100%; } }

.p-music-artist-comment {
  font-size: 18px;
  color: #32c7ff;
  font-weight: normal; }

.p-music__artist-name {
  display: block;
  font-size: 18px;
  color: #32c7ff;
  line-height: 1;
  margin-bottom: 10px; }

.l-staff-title {
  font-size: 25px;
  color: #32c7ff;
  font-weight: normal;
  margin-bottom: 20px; }
  @media only screen and (max-width: 768px) {
    .l-staff-title {
      text-align: center; } }

.p-staff__item {
  margin-bottom: 3em; }
  .p-staff__item strong {
    font-size: 18px;
    font-weight: normal; }
  @media only screen and (max-width: 768px) {
    .p-staff__item {
      text-align: center;
      margin-bottom: 1.8em; }
      .p-staff__item span {
        display: block;
        line-height: 1.3; } }

.p-staff-photo {
  display: block;
  width: 200px;
  margin-left: 25px; }
  @media only screen and (max-width: 768px) {
    .p-staff-photo {
      margin: auto;
      margin-top: 10px;
      width: 80%; } }

.u-btn_comment {
  width: 60px;
  height: 20px;
  margin-left: 8px;
  display: inline-block;
  background: url(../img/staff/icon_staff_comment.png) center no-repeat;
  background-size: contain; }

.p-cast__content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }
  .p-cast__content-box .p-staff__item {
    width: 50%; }
    @media only screen and (max-width: 768px) {
      .p-cast__content-box .p-staff__item {
        width: auto;
        width: 100%; } }

.p-book__content {
  margin-bottom: 4rem; }
  @media only screen and (max-width: 768px) {
    .p-book__content {
      text-align: center;
      margin-bottom: 3em; } }

.p-book__subtitle {
  font-family: "Noto Sans Japanese", sans-serif;
  font-weight: 200;
  margin-bottom: .8em; }

.p-book__title {
  line-height: 1.4;
  font-size: 140%;
  font-feature-settings: "palt";
  margin-bottom: .6em; }

.p-book__author {
  font-family: "Noto Sans Japanese", sans-serif;
  font-weight: 200;
  margin-bottom: 1.4em;
  line-height: 1.4; }
  @media only screen and (max-width: 768px) {
    .p-book__author span {
      display: block;
      padding-top: .5rem; } }

.p-book__description {
  display: flex;
  justify-content: space-between;
  align-items: stretch; }
  @media only screen and (max-width: 768px) {
    .p-book__description {
      flex-direction: column;
      text-align: left;
      line-height: 1.6; } }

.p-book__figure {
  width: 190px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 2.2;
  font-family: "Noto Sans Japanese", sans-serif;
  font-weight: 200;
  color: white;
  flex: 1 0 auto; }
  .p-book__figure img {
    width: 100%; }
  @media only screen and (max-width: 768px) {
    .p-book__figure {
      margin: auto;
      height: 50vh;
      width: 60vw;
      margin-bottom: 2em; } }

.p-book__description-data {
  text-align: right;
  margin-top: 3rem; }
  @media only screen and (max-width: 768px) {
    .p-book__description-data {
      margin-top: 1em;
      text-align: center; } }

.l-author-title {
  font-size: 13px;
  margin-bottom: 30px; }
  .l-author-title b {
    font-size: 18px;
    font-weight: normal; }
  .l-author-title strong {
    font-weight: normal; }
  @media only screen and (max-width: 768px) {
    .l-author-title {
      padding: 0 16px;
      text-align: center; }
      .l-author-title strong {
        display: block; } }

.p-comic__items {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

.p-comic__item {
  width: 180px;
  width: calc(25% - 30px);
  margin-bottom: 60px; }
  @media only screen and (max-width: 768px) {
    .p-comic__item {
      width: calc(50% - 8px);
      margin-bottom: 16px; }
      .p-comic__item:nth-child(odd) {
        margin-right: 16px; } }
  .p-comic__item img {
    width: 100%; }

.p-movie__content-item {
  margin-bottom: 40px;
  text-align: center;
  line-height: 1.8; }
  @media only screen and (max-width: 768px) {
    .p-movie__content-item iframe {
      width: 100%;
      height: calc( (100vw - 32px) * 0.5625); } }

.p-character-home {
  width: 620px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media only screen and (max-width: 768px) {
    .p-character-home {
      width: auto;
      flex-direction: column; } }

.p-character-home__item {
  width: 45%;
  position: relative;
  margin-bottom: 60px; }
  .p-character-home__item img {
    width: auto;
    max-width: initial;
    max-width: auto;
    height: 230px; }
  @media only screen and (max-width: 768px) {
    .p-character-home__item {
      width: 70%;
      margin: auto;
      margin-bottom: 20px; }
      .p-character-home__item img {
        height: 30vh;
        position: relative;
        left: -20px; } }

.p-character-home__name {
  width: 100%;
  height: 100%;
  position: absolute;
  right: -10px;
  top: 0;
  /*display: table-cell;
	vertical-align: middle;*/
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 22px;
  padding-top: 50px;
  color: #6e5748;
  letter-spacing: 0.1em; }
  .p-character-home__name span {
    font-size: 12px;
    color: #32c7ff; }

.p-character-home__name-wrap {
  text-align: right; }
  .p-character-home__name-wrap span {
    display: block;
    letter-spacing: 0; }

.p-character__character-item {
  width: 570px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch; }
  .p-character__character-item img {
    width: auto; }
  @media only screen and (max-width: 768px) {
    .p-character__character-item {
      width: auto;
      width: 100%; } }

.l-character__left {
  width: 350px;
  /*@include fb(space-between, center);
	flex-direction: column;*/ }
  @media only screen and (max-width: 768px) {
    .l-character__left {
      width: 50%;
      flex: 1 0 auto; } }

.l-character__left-upper {
  max-width: 100%;
  flex: 1 0 auto; }

.l-character__left-lower {
  flex: 1 0 auto; }
  @media only screen and (max-width: 768px) {
    .l-character__left-lower {
      display: none; } }

.l-character__body-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  padding-left: 40px;
  max-width: 100%;
  flex: 1 0 auto; }
  .l-character__body-wrapper img {
    height: 600px;
    width: auto;
    max-width: auto;
    max-width: initial; }
  .l-character__body-wrapper .p-character__body {
    height: 100%; }
  @media only screen and (max-width: 768px) {
    .l-character__body-wrapper {
      display: block;
      width: 50%;
      padding-left: 0;
      flex: 1 0 auto; }
      .l-character__body-wrapper .p-character__body {
        text-align: center; }
      .l-character__body-wrapper img {
        height: 80vh;
        display: inline;
        max-width: auto;
        max-width: initial; } }

@media only screen and (max-width: 768px) {
  .is-no-face .l-character__left {
    z-index: 4;
    width: 40%;
    position: relative; } }

@media only screen and (max-width: 768px) {
  .is-no-face .l-character__body-wrapper {
    width: 60%;
    position: relative; }
    .is-no-face .l-character__body-wrapper img {
      height: auto;
      width: 100%; } }

.p-character__name-en {
  color: #32c7ff; }

.p-character__name-jp {
  font-size: 200%;
  letter-spacing: 0.1em; }
  @media only screen and (max-width: 768px) {
    .p-character__name-jp {
      font-size: 6vw; } }

.p-character__cv {
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 40px; }
  .p-character__cv span {
    font-size: 10px; }
  .p-character__cv .p-character__cv-content {
    margin-left: 10px;
    font-size: 110%; }

.p-character__description {
  margin-top: 20px;
  line-height: 2.0;
  color: #6e5748; }
  @media only screen and (max-width: 768px) {
    .p-character__description {
      display: none; } }

.p-character__nav {
  margin-top: 40px;
  text-align: center; }
  .p-character__nav .p-character__nav-home {
    margin: 0 4em;
    text-decoration: none;
    color: #6e5748; }
  .p-character__nav img {
    width: 10px; }
  @media only screen and (max-width: 768px) {
    .p-character__nav .p-character__nav-home {
      font-size: 120%; } }

.p-character__change-wrapper {
  width: 700px;
  margin: auto;
  padding: 0 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  @media only screen and (max-width: 768px) {
    .p-character__change-wrapper {
      width: auto;
      padding: 0 20px 0; } }

.l-character__face-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 40px; }
  .l-character__face-wrapper img {
    width: 100%;
    max-width: initial;
    max-width: auto;
    vertical-align: bottom; }
  .l-character__face-wrapper .p-character__face {
    width: 48%; }
  @media only screen and (max-width: 768px) {
    .l-character__face-wrapper {
      width: 100%;
      margin: 20px 0;
      display: block; }
      .l-character__face-wrapper .p-character__face {
        margin: 0 0 10px;
        width: 100%;
        text-align: center; }
        .l-character__face-wrapper .p-character__face img {
          height: 40vw;
          width: auto;
          max-width: initial;
          max-width: auto; } }

.l-character-data-clone {
  width: 100%;
  clear: both;
  display: none; }
  @media only screen and (max-width: 768px) {
    .l-character-data-clone {
      display: block; }
      .l-character-data-clone .p-character__description, .l-character-data-clone .l-character__left-lower {
        display: block; }
      .l-character-data-clone .p-character__description {
        padding: 0 2rem; } }

.l-special-title {
  border-left: 3px solid #6e5748;
  line-height: 2.0;
  padding-left: 20px;
  font-size: 130%;
  margin-bottom: 30px; }

.p-special__desc {
  margin-bottom: 2rem; }
  .p-special__desc p {
    line-height: 1.3;
    margin-bottom: .5rem; }

.p-special__content-box dl {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 2.0;
  margin-bottom: 16px; }

.p-special__content-box dt {
  margin-right: 10px; }
  .p-special__content-box dt::after {
    content: ":";
    margin-left: 10px; }
  @media only screen and (max-width: 768px) {
    .p-special__content-box dt.p-special__content-cast {
      min-width: 3em; } }

.p-special-apply {
  text-align: center;
  margin: 40px 0; }
  .p-special-apply a {
    box-shadow: 2px 2px 4px rgba(110, 87, 72, 0.5);
    display: inline-block;
    margin: auto;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: .2em;
    color: #6e5748; }
    .p-special-apply a span {
      display: block;
      margin: 3px;
      border: 1px solid rgba(110, 87, 72, 0.3);
      padding: 7px 30px 5px; }
    .p-special-apply a:hover {
      background-color: rgba(110, 87, 72, 0.1); }
      .p-special-apply a:hover span {
        border: 1px solid rgba(110, 87, 72, 0.7); }

.p-special-event-ended {
  border: 1px solid rgba(110, 87, 72, 0.8);
  font-size: 18px;
  letter-spacing: .2em;
  color: #6e5748;
  padding: 7px 30px 5px;
  text-align: center;
  margin-top: 2rem; }
  @media only screen and (max-width: 768px) {
    .p-special-event-ended {
      letter-spacing: 0;
      padding: 7px 10px 5px; } }

@media only screen and (max-width: 768px) {
  .p-special-pv iframe {
    width: 100%;
    height: calc( (100vw - 32px) * 0.5625); } }
